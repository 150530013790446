<template>
    <div>
        <div class="header">
            <span style="margin-right: 7px; display:inline-block">Support E-Mail : mail@linktechbd.com</span> -
            <span style="margin-left: 5px">Support Phone :</span> +88 01911978897
            <!-- <span style="margin-right: 7px; display:inline-block">Support E-Mail : mail@bigsoftwareltd.com</span> -
            <span style="margin-left: 5px">Support Phone :</span> +88 01911-444811 -->
        </div>
        <div class="login__main">
            <v-container>
                <h2 v-if="$store.getters['companyProfile/company'] != null" class="brand">
                    {{ $store.getters['companyProfile/company'].company_name }}
                </h2>
                <p class="software">Diagnostic Centre Management Software</p>
                <v-row>
                    <v-col cols="7">
                        <v-img
                            lazy-src="@/assets/login__img.png"
                            src="@/assets/login__img.png"
                            contain
                            height="70vh"
                        ></v-img>
                    </v-col>
                    <v-col cols="5">
                        <v-card
                            max-width="344"
                            class="login__form__wrapper"
                        >
                            <v-card-text>
                                <h2 class="login__head">Login</h2>
                                <v-form ref="loginForm" @submit.prevent="login">
                                    <v-text-field 
                                        prepend-icon="mdi-account" 
                                        name="Username" 
                                        label="Username"
                                        :rules="[v => !!v || 'Username is required']"
                                        v-model="user.username"
                                    ></v-text-field>
                                    <v-text-field 
                                        prepend-icon="mdi-form-textbox-password" 
                                        name="Password" 
                                        label="Password" 
                                        type="password"
                                        :rules="[v => !!v || 'Password is required']"
                                        v-model="user.password"
                                    ></v-text-field>
                                    <v-card-actions>
                                        <v-btn type="submit" :loading="waitingForSave" color="#1697f6" dark large block>Login</v-btn>
                                    </v-card-actions>
                                </v-form>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <div class="footer">
            &copy; 2020 | Developed by <a href="http://linktechbd.com" target="_blank">Link-Up Technology Ltd.</a>
            <!-- &copy; 2020 | Developed by <a href="http://bigsoftwareltd.com" target="_blank">Big Software Limited</a> -->
        </div>
    </div>
</template>

<script>
export default {
    name: "Login",
    data() {
        return {
            apiBasePath: window.apiBaseURL,

            user: {
                username: '',
                password: '',
            },

            waitingForSave: false,
        }
    },
    created() {
        this.$store.dispatch('companyProfile/getCompanyProfile')
    },
    async mounted() {
        let token = localStorage.getItem('_accessToken')
        if (token) this.$router.push('/')
    },
    methods: {
        async login() {
            if (!this.$refs.loginForm.validate()) return
            this.waitingForSave = true
            let res = await this.$store.dispatch('auth/login', {...this.user})
            if (res) this.$router.push('/')
            this.waitingForSave = false
        },
    },
}
</script>

<style lang="scss" scoped>
.login__main {
    .brand {
        text-align: center;
        margin-top: 20px;
        font-size: 30px;
        letter-spacing: 1px;
        font-family: Arial, Helvetica, sans-serif;
        background: -webkit-linear-gradient(#000, #44af91);
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    background: url('../../assets/login__bg.jpg') no-repeat;
    background-size: cover;
    position: fixed;
    height: 100%;
    width: 100%;

    .login__form__wrapper {
        margin-top: 40px;
        background: linear-gradient(to right,  #72fcd4, #ffffff);
        border-radius: 10px;
        padding: 40px 0;

        .login__head {
            text-align: center;
            margin-bottom: 20px;
            font-family: Arial, Helvetica, sans-serif;
            color: black;
        }
    }
}
.software {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    color: rgb(0, 0, 0, .7);
}
.header {
    text-align: right;
    background: #000;
    color: #ffffff;
    padding: 3px 10px;
    font-size: 13px;
}
.footer {
    position: absolute;
    bottom: 0;
    text-align: center;
    background: #000;
    width: 100%;
    color: #ffffff;
    font-size: 12px;
    padding: 4px 0;
    letter-spacing: 1px;
    font-weight: 300 !important;

    a {
        color: #ffffff;
        text-decoration: none;
    }
}
</style>